import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";
import AboutPage from "./pages/About.js";
import TeamPage from "./pages/Team.js";
import PartnersPage from "./pages/Partners.js";
import ContactPage from "./pages/Contact.js";
import _404Page from "./pages/404.js";
import TosPage from "./pages/ToS.js";
import PrivacyPage from "./pages/Privacy.js";
import usePlausible from './usePlausible';

function App() {
  usePlausible(); 
  return (
    <Routes>
      <Route exact path="/" element={<IndexPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/partners" element={<PartnersPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/terms" element={<TosPage />} />
      <Route path="/privacy" element={<PrivacyPage />}/>
      <Route path="*" element={<_404Page />} />
    </Routes>
  );
}

export default App;
