// src/hooks/usePlausible.js
import { useEffect } from 'react';
import Plausible from 'plausible-tracker';

const usePlausible = () => {
  useEffect(() => {
    const plausible = Plausible({
      domain: 'https://noiresports.com', // Replace with your domain
      apiHost: 'https://analytics.elele.team'
    });
    plausible.enableAutoPageviews(); // Track page views automatically
    plausible.enableAutoOutboundTracking(); // Optional: Track outbound links automatically
  }, []);
};

export default usePlausible;
