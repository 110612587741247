import React from "react";

export default function _404SectionHttpCodes1() {
  return (
    <React.Fragment>
      <>
        <section className="bg-darkPink-900 pt-32 pb-40">
          <div className="container mx-auto px-4">
            <img
              className="mx-auto mb-24 h-56 object-cover"
              src="vista-assets/images/http-codes/illustration.svg"
              alt
            />
            <h1 className="font-heading text-white text-center text-4xl font-semibold mb-4">
              Oops. Page not found.
            </h1>
            <p className="text-white text-center text-opacity-70 text-lg mb-10 max-w-lg mx-auto">
              The page you are looking for was moved, removed, renamed or might
              have never existed!
            </p>
            <div className="text-center">
              <a
                href="/"
                className="group relative inline-block p-0.5 font-semibold overflow-hidden rounded-full shadow-pink hover:shadow-none focus:shadow-none"
              >
                <div className="absolute inset-0 bg-gradient-to-b from-pink-400 to-pink-500 rounded-full" />
                <div className="relative z-50 flex items-center py-2 px-4 bg-pink-500 group-hover:bg-pink-600 group-focus:bg-pink-600 rounded-full transition duration-200">
                  <p className="mr-2 text-white">Back to home</p>
                  <svg
                    className="text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width={17}
                    height={16}
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M6 3L11 8L6 13"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
