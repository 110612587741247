import React from "react";

export default function IndexSectionCareers3() {
  return (
    <section className="bg-darkPink-900 pb-40">
      <div className="container mx-auto px-4">
        <div className="pt-24 text-center">
          {/* Centered Header */}
          <h2 className="font-heading text-white text-5xl font-bold mb-8 text-center">
            OUR GAMES
          </h2>
          {/* Centered Paragraph */}
          <p className="text-white text-opacity-80 mb-16 max-w-3xl mx-auto text-lg">
            At Noir eSports, we compete across a variety of the most popular and competitive games in the world. From high-octane shooters to strategic battle arenas, our teams excel in titles that test skill, teamwork, and strategy.
          </p>
          
          {/* Flex Content Centered */}
          <div className="flex flex-wrap justify-center gap-8">
            {/* Card 1 */}
            <div className="w-full lg:w-1/3 transform transition-transform duration-300 hover:scale-105">
              <div className="bg-gradient-to-t from-pink-400 via-pink-600 to-darkPink-900 rounded-3xl p-px shadow-xl">
                <div className="bg-darkPink-900 rounded-3xl p-8">
                  <p className="text-white text-2xl font-bold mb-2 text-center">
                    VALORANT
                  </p>
                  <p className="text-white text-opacity-80 max-w-xs mx-auto text-center">
                    Noir Aster - Game Changers
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="w-full lg:w-1/3 transform transition-transform duration-300 hover:scale-105">
              <div className="bg-gradient-to-b from-pink-400 via-pink-600 to-darkPink-900 rounded-3xl p-px shadow-xl">
                <div className="bg-darkPink-900 rounded-3xl p-8">
                  <p className="text-white text-2xl font-bold mb-2 text-center">
                    Counter Strike
                  </p>
                  <p className="text-white text-opacity-80 max-w-xs mx-auto text-center">
                    Coming Soon to Noir eSports
                  </p>
                </div>
              </div>
            </div>

            {/* Additional Card Example */}
            <div className="w-full lg:w-1/3 transform transition-transform duration-300 hover:scale-105">
              <div className="bg-gradient-to-b from-pink-400 via-pink-600 to-darkPink-900 rounded-3xl p-px shadow-xl">
                <div className="bg-darkPink-900 rounded-3xl p-8">
                  <p className="text-white text-2xl font-bold mb-2 text-center">
                    What's next?
                  </p>
                  <p className="text-white text-opacity-80 max-w-xs mx-auto text-center">
                    We are always looking to expand!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
