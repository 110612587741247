import React from "react";

export default function PartnerLogoSection() {
  // Sample data for partners
  const partners = [
    {
      name: "Rogue Energy",
      logo: "https://static.shuffle.dev/uploads/files/2d/2dfcc02636d68d0e1797401e9cf57d98fc8e28df/RogueEnergy-4556705d69d06962133e.png",
      discountCode: "NOIR", 
      website: "https://rogueenergy.com/discount/NOIR?ref=scrvnwhm", 
    },
  ];

  return (
    <React.Fragment>
      <section className="bg-darkPink-900 py-24">
        <div className="container mx-auto px-4">
          <p className="font-heading text-center text-white text-2xl font-bold mb-16">
            Our Current Partners
          </p>
          <div className="flex flex-wrap justify-center items-center gap-8 mb-20">
            {partners.map((partner, index) => (
              <div key={index} className="w-full sm:w-auto text-center p-4">
                <img
                  className="h-12 mx-auto mb-4 object-contain"
                  src={partner.logo}
                  alt={`${partner.name} Logo`}
                />
                <p className="text-white font-medium">{partner.name}</p>
                {partner.discountCode && (
                  <p className="text-pink-500 font-semibold text-sm mt-2">
                    Discount Code: {partner.discountCode}
                  </p>
                )}
                {partner.website && (
                  <a
                    href={partner.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-pink-400 hover:text-pink-300 transition duration-200 text-sm font-medium mt-2 inline-block"
                  >
                    Visit Website
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
