import React from "react";

export default function TeamSectionTeam2() {
  // Data for the team members
  const teamMembers = [
    {
      name: "Jack I.",
      role: "Founder & CEO",
      imageUrl:
        "https://static.shuffle.dev/uploads/files/a8/a83dfe198816d92519b09c2864ecd13a740a1a46/35XV4c7t-400x400-removebg-preview.png",
      socials: {
        twitter: "https://twitter.com/Jackelele_",
      },
    },
    {
      name: "Hunter",
      role: "Chief Technology Officer",
      imageUrl:
        "https://static.shuffle.dev/uploads/files/a8/a83dfe198816d92519b09c2864ecd13a740a1a46/Hunter-removebg-preview.png",
      socials: {
        twitter: "https://x.com/huntarr_",
      },
    },
    {
      name: "Matty",
      role: "Head of Marketing",
      imageUrl: "vista-assets/images/team/team3.png",
      socials: {
        twitter: "https://x.com/Mattyelele",
      },
    },
  ];

  return (
    <section className="bg-darkPink-900 pt-32 pb-56">
      <div className="container mx-auto px-4">
        <p className="uppercase text-sweetPink-400 tracking-widest text-xs mb-4">
          diverse and world-class
        </p>
        <div className="flex justify-between items-center flex-wrap gap-4 mb-20">
          <h1 className="font-heading text-white text-4xl md:text-5xl font-bold">
            BEHIND THE SCENES
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
          {teamMembers.map((member, index) => (
            <div key={index} className="w-full lg:w-1/3 p-4">
              <div className="relative bg-pink-500 rounded-2xl pt-7 mb-12 lg:mb-0">
                <img
                  className="mx-auto object-cover h-72"
                  src={member.imageUrl}
                  alt={`${member.name}'s photo`}
                />
                <div className="absolute -bottom-12 left-4 right-4">
                  <div className="bg-pinkSecondary-900 rounded-xl p-4">
                    <p className="text-white text-lg font-semibold mb-1">
                      {member.name}
                    </p>
                    <div className="flex flex-wrap justify-between items-center gap-4">
                      <p className="text-white text-opacity-70 text-sm">
                        {member.role}
                      </p>
                      <div className="flex gap-4">
                        {member.socials.twitter && (
                          <a
                            href={member.socials.twitter}
                            className="inline-block opacity-50 hover:opacity-100 transition duration-200"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="h-4"
                              src="vista-assets/images/logos/twitter-x-logo.svg"
                              alt="Twitter logo"
                            />
                          </a>
                        )}
                        {member.socials.linkedin && (
                          <a
                            href={member.socials.linkedin}
                            className="inline-block opacity-50 hover:opacity-100 transition duration-200"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="h-4"
                              src="vista-assets/images/logos/linkedin-logo.svg"
                              alt="LinkedIn logo"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
