import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders6 from '../components/headers/IndexSectionHeaders6';
import IndexSectionCareers2 from '../components/careers/IndexSectionCareers2';
import IndexSectionCareers3 from '../components/careers/IndexSectionCareers3';
import IndexSectionFooter4 from '../components/footer/IndexSectionFooter4';

const meta = {
  title: 'Noir | Home',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders6 />
      <IndexSectionCareers2 />
      <IndexSectionCareers3 />
      <IndexSectionFooter4 />
    </React.Fragment>
  );
}

