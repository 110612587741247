import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TeamSectionNavigations3 from '../components/navigations/TeamSectionNavigations3';
import TeamSectionAbout4 from '../components/about/TeamSectionAbout4';
import TeamSectionTeam2 from '../components/team/TeamSectionTeam2';
import IndexSectionFooter4 from '../components/footer/IndexSectionFooter4';
import TeamSectionTeam1 from '../components/team/TeamSectionTeam1';

const meta = {
  title: 'Noir | Team',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Team() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <TeamSectionNavigations3 />
      <TeamSectionAbout4 />
      <TeamSectionTeam2 />
      <TeamSectionTeam1 />
      <IndexSectionFooter4 />
    </React.Fragment>
  );
}

