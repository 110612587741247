import React, { useState } from "react";
import { FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa";
import VALORANTpng from "../../assets/VALORANT.png";
import Aster from "../../assets/NoirGC-d4c92a21ef710df03127.png";
import Anonymo from "../../assets/gszfacrY_400x400.jpg";

export default function MatchesSection() {
  const [activeTab, setActiveTab] = useState("past");
  const [activeDropdown, setActiveDropdown] = useState(null);

  const pastMatches = [
    {
      game: "VALORANT",
      tournament: "EVC x Crunch Game Changers",
      date: "July 18, 2024",
      team1: {
        name: "Noir Aster",
        logo: Aster,
        score: 0,
      },
      team2: {
        name: "Anonymo GC",
        logo: Anonymo,
        score: 2,
      },
    },
    {
      game: "VALORANT",
      tournament: "EVC x Crunch Game Changers",
      date: "July 17, 2024",
      team1: {
        name: "Noir Aster",
        logo: Aster,
        score: 2,
      },
      team2: {
        name: "Zyron Sadashi",
        logo: VALORANTpng,
        score: 0,
      },
    },
    {
      game: "VALORANT",
      tournament: "EVC x Crunch Game Changers",
      date: "July 16, 2024",
      team1: {
        name: "Noir Aster",
        logo: Aster,
        score: 0,
      },
      team2: {
        name: "PURE GC",
        logo: VALORANTpng,
        score: 2,
      },
    },
    {
      game: "VALORANT",
      tournament: "EVC x Crunch Game Changers",
      date: "July 16, 2024",
      team1: {
        name: "Noir Aster",
        logo: Aster,
        score: 2,
      },
      team2: {
        name: "Crvena zvezda esports",
        logo: VALORANTpng,
        score: 1,
      },
    },
  ];

  const upcomingMatches = [
    {
      game: "VALORANT",
      tournament: "Unknown",
      date: "Unknown, 2024",
      team1: {
        name: "Noir Aster",
        logo: Aster,
        score: null,
      },
      team2: {
        name: "Unknown Team",
        logo: VALORANTpng,
        score: null,
      },
    },
  ];

  const matchesToDisplay = activeTab === "past" ? pastMatches : upcomingMatches;

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <>
      <section className="bg-darkPink-900 pt-24 pb-24">
        <h2 className="font-heading text-white text-center text-4xl font-bold mb-10">
          OUR MATCHES
        </h2>
        <div className="container mx-auto px-4">
          {/* Tab Header */}
          <div className="flex justify-center space-x-4 mb-10">
            <button
              onClick={() => setActiveTab("past")}
              className={`px-4 py-2 font-semibold rounded ${
                activeTab === "past"
                  ? "bg-pink-600 text-white"
                  : "bg-pink-200 text-black hover:bg-pink-400"
              } transition duration-300`}
            >
              PAST RESULTS
            </button>
            <button
              onClick={() => setActiveTab("upcoming")}
              className={`px-4 py-2 font-semibold rounded ${
                activeTab === "upcoming"
                  ? "bg-pink-600 text-white"
                  : "bg-pink-200 text-black hover:bg-pink-400"
              } transition duration-300`}
            >
              UPCOMING
            </button>
          </div>

          {/* Matches Dropdown */}
          {matchesToDisplay.map((match, index) => (
            <div key={index} className="mb-6">
              {/* Match Link that Toggles the Dropdown */}
              <div
                onClick={() => toggleDropdown(index)}
                className="inline-block mb-4 w-full group cursor-pointer"
              >
                <div className="bg-gradient-to-t from-pink-300 via-pink-700 to-darkPink-900 rounded-2xl p-px">
                  <div className="bg-pinkSecondary-900 rounded-2xl px-8 py-5">
                    <div className="flex flex-wrap -mx-4">
                      <div className="w-full sm:w-1/2 px-4">
                        <p className="text-white text-lg font-medium mb-2 sm:mb-0">
                          {match.tournament}
                        </p>
                        <p className="text-gray-400 text-sm">{match.date}</p>
                      </div>
                      <div className="w-full sm:w-1/2 px-4">
                        <div className="flex flex-wrap justify-between items-center gap-4">
                          <p className="text-white text-opacity-70 group-hover:text-opacity-100 transition duration-200">
                            {match.game}
                          </p>
                          <span className="text-white text-opacity-50 group-hover:text-opacity-100 transition duration-200">
                            {activeDropdown === index ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M3.75 6.875L10 13.125L16.25 6.875"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M6.875 3.75L13.125 10L6.875 16.25"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dropdown Content */}
              {activeDropdown === index && (
                <div className="bg-pinkSecondary-900 rounded-2xl px-8 py-5 mt-4">
                  <div className="flex items-center justify-between my-8">
                    {/* Team 1 */}
                    <div className="flex flex-col items-center w-1/3">
                      <img
                        src={match.team1.logo}
                        alt={`${match.team1.name} logo`}
                        className="h-12 w-12 object-cover rounded-full mb-2"
                      />
                      <p className="text-white text-lg font-semibold">
                        {match.team1.name}
                      </p>
                      <p className="text-white text-xl font-semibold mt-2">
                        {match.team1.score}
                      </p>
                    </div>

                    {/* VS Text */}
                    <div className="w-1/3 flex justify-center">
                      <p className="text-white text-3xl font-bold">VS</p>
                    </div>

                    {/* Team 2 */}
                    <div className="flex flex-col items-center w-1/3">
                      <img
                        src={match.team2.logo}
                        alt={`${match.team2.name} logo`}
                        className="h-12 w-12 object-cover rounded-full mb-2"
                      />
                      <p className="text-white text-lg font-semibold">
                        {match.team2.name}
                      </p>
                      <p className="text-white text-xl font-semibold mt-2">
                        {match.team2.score}
                      </p>
                    </div>
                  </div>

                  {/* Score Progress Bar */}
                  {match.team1.score !== null && match.team2.score !== null ? (
                    <>
                      <div className="flex justify-between items-center">
                        <div className="w-full bg-gray-800 h-1 mx-4 mt-4 relative flex">
                          {/* Team 1 */}
                          <div
                            className={`h-1 ${
                              match.team1.score >= match.team2.score
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                            style={{
                              width:
                                match.team1.score === 0
                                  ? "1%"
                                  : (match.team1.score /
                                      (match.team1.score + match.team2.score)) *
                                      100 +
                                    "%",
                            }}
                          ></div>

                          {/* Team 2 */}
                          <div
                            className={`h-1 ${
                              match.team2.score > match.team1.score
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                            style={{
                              width:
                                match.team2.score === 0
                                  ? "1%"
                                  : (match.team2.score /
                                      (match.team1.score + match.team2.score)) *
                                      100 +
                                    "%",
                            }}
                          ></div>
                        </div>
                      </div>

                      {/* Winner Display */}
                      <div className="text-center mt-4">
                        {match.team1.score > match.team2.score ? (
                          <p className="text-2xl font-bold text-pink-500">
                            Winner: {match.team1.name}
                          </p>
                        ) : match.team2.score > match.team1.score ? (
                          <p className="text-2xl font-bold text-red-500">
                            Winner: {match.team2.name}
                          </p>
                        ) : (
                          <p className="text-2xl font-bold text-yellow-500">
                            It's a Draw!
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    <p className="text-white text-lg mt-4">
                      Match details coming soon.
                    </p>
                  )}
                </div>
              )}
            </div>
          ))}

          {/* Button to Load More Matches */}
          <div className="flex justify-center mt-6">
            <a
              href="https://www.rib.gg/teams/noir-aster/matches/16758"
              className="inline-block px-8 py-4 text-lg font-bold text-white rounded-full bg-gradient-to-r from-yellow-500 via-red-500 to-pink-500 hover:scale-105 transform transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to see more matches
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
