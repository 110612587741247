import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _404SectionNavigations2 from '../components/navigations/_404SectionNavigations2';
import _404SectionHttpCodes1 from '../components/http-codes/_404SectionHttpCodes1';
import IndexSectionFooter4 from '../components/footer/IndexSectionFooter4';

const meta = {
  title: 'Noir | Error',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function _404() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <_404SectionNavigations2 />
      <_404SectionHttpCodes1 />
      <IndexSectionFooter4 />
    </React.Fragment>
  );
}

