import React from "react";
import { FaTiktok, FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function IndexSectionFooter4() {
  const socialLinks = [
    {
      href: "https://x.com/noiresportsorg",
      label: "Twitter",
      icon: <FaXTwitter />,
    },
    {
      href: "https://www.tiktok.com/@noir.esports",
      label: "TikTok",
      icon: <FaTiktok  />,
    },
    {
      href: "https://noir.army/discord",
      label: "Discord",
      icon: <FaDiscord  />,
    },
  ];

  return (
    <section className="bg-darkPink-900 py-20">
      <div className="container mx-auto px-4">
        <div className="pb-20 border-b border-white border-opacity-30 mb-10">
          <div className="flex flex-wrap justify-between -m-4">
            {/* Company Logo */}
            <div className="w-full lg:w-1/6 p-4">
              <a
                href="#"
                className="inline-block text-white font-bold text-2xl"
              >
                Noir eSports
              </a>
            </div>

            {/* Navigation Links Column 1 */}
            <div className="w-full sm:w-1/2 lg:w-1/6 p-4">
              <ul className="flex flex-col gap-6">
                <li>
                  <a
                    href="/about"
                    className="text-white hover:text-opacity-70 text-xl transition duration-200"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/team"
                    className="text-white hover:text-opacity-70 text-xl transition duration-200"
                  >
                    Team
                  </a>
                </li>
              </ul>
            </div>

            {/* Navigation Links Column 2 */}
            <div className="w-full sm:w-1/2 lg:w-1/6 p-4">
              <ul className="flex flex-col gap-6">
                <li>
                  <a
                    href="/partners"
                    className="text-white hover:text-opacity-70 text-xl transition duration-200"
                  >
                    Partners
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className="text-white hover:text-opacity-70 text-xl transition duration-200"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            {/* Navigation Links Column 3 */}
            <div className="w-full sm:w-1/2 lg:w-1/6 p-4">
              <ul className="flex flex-col gap-6">
                <li>
                  <a
                    href="/terms"
                    className="text-white hover:text-opacity-70 text-xl transition duration-200"
                  >
                    Terms Of Service
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy"
                    className="text-white hover:text-opacity-70 text-xl transition duration-200"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="flex justify-between items-center flex-wrap gap-6">
          <p className="text-white">
            <span className="font-semibold">Noir eSports LTD</span> <br />A
            subsidiary of{" "}
            <a
              className="font-bold text-white hover:text-opacity-70 transition duration-200"
              target="_blank"
              href="https://elele.group"
              rel="noopener noreferrer"
            >
              Elele Group
            </a>{" "}
            <br />© 2024 Noir eSports LTD. All Rights Reserved.
          </p>

          {/* Social Media Icons */}
          <div className="flex flex-wrap gap-6">
            {socialLinks.map((social, index) => (
              <a
                key={index}
                href={social.href}
                target="_blank"
                aria-label={social.label}
                rel="noopener noreferrer"
                className="text-white hover:text-opacity-70 transition duration-200 text-3xl"
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
