import React from "react";

export default function TeamSectionAbout4() {
  return (
    <React.Fragment>
      <>
        <section className="bg-darkPink-900 pt-24 pb-9 relative">
          <div className="container mx-auto px-4">
            <img
              className="absolute right-0 top-0 object-cover"
              style={{ height: 600 }}
              src="vista-assets/images/about/illustration-pink2.svg"
              alt
            />
            <div className="relative z-50">
              <h1 className="font-heading text-white text-5xl md:text-6xl font-semibold max-w-2xl mb-28">
                Champions in the Making: Meet Our Teams
              </h1>
              <div className="border-b border-pink-500 pb-9 mb-32">
                <div className="bg-darkPink-900"></div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
