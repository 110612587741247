import React from "react";

export default function TeamSection() {
  // Sample data for team members
  const teamMembers = [
    { name: "Banu", role: "Relations Manager", game: "Noir Staff" },
    { name: "Timmwy13", role: "Team Manager", game: "Noir Aster"},
    { name: "Zzoeh", role: "Team Captain", game: "Noir Aster" },
  ];

  return (
    <section className="bg-darkPink-900 pb-20 px-6 min-h-screen flex items-center justify-center">
      <div className="container mx-auto text-center">
        <h2 className="font-heading text-white text-5xl font-bold mb-16">
          Our Team
        </h2>
        <div className="overflow-x-auto w-full lg:w-3/4 mx-auto">
          <div className="bg-gradient-to-t from-pink-300 via-pink-700 to-darkPink-900 rounded-3xl p-2 shadow-lg">
            <div className="bg-pinkSecondary-900 rounded-3xl px-8 py-6">
              <table className="w-full table-auto text-white rounded-lg shadow-md overflow-hidden border-collapse">
                <thead>
                  <tr className="bg-gradient-to-r from-red-600 to-pink-600">
                    <th className="px-8 py-6 font-semibold text-lg uppercase text-center border-b border-pink-800">
                      Name
                    </th>
                    <th className="px-8 py-6 font-semibold text-lg uppercase text-center border-b border-pink-800">
                      Role
                    </th>
                    <th className="px-8 py-6 font-semibold text-lg uppercase text-center border-b border-pink-800">
                      Department
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {teamMembers.map((member, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-pink-800" : "bg-pink-900"
                      } hover:bg-gradient-to-r from-pink-700 to-red-500 transition duration-300`}
                    >
                      <td className="px-8 py-6 text-base border-b border-pink-800">
                        {member.name}
                      </td>
                      <td className="px-8 py-6 text-base border-b border-pink-800">
                        {member.role}
                      </td>
                      <td className="px-8 py-6 text-base border-b border-pink-800">
                        {member.game}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
