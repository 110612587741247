import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContactSectionNavigations1 from '../components/navigations/ContactSectionNavigations1';
import ContactSectionContact2 from '../components/contact/ContactSectionContact2';
import ContactSectionFaq4 from '../components/faq/ContactSectionFaq4';
import IndexSectionFooter4 from '../components/footer/IndexSectionFooter4';

const meta = {
  title: 'Noir | Contact',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <ContactSectionNavigations1 />
      <ContactSectionContact2 />
      <IndexSectionFooter4 />
    </React.Fragment>
  );
}

