import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PartnersSectionNavigations1 from "../components/navigations/PartnersSectionNavigations1";
import PartnersSectionCta2 from "../components/cta/PartnersSectionCta2";
import PartnersSectionLogoClouds4 from "../components/logo-clouds/PartnersSectionLogoClouds4";
import PartnersSectionTestimonials3 from "../components/testimonials/PartnersSectionTestimonials3";
import IndexSectionFooter4 from "../components/footer/IndexSectionFooter4";

const meta = {
  title: "Noir | Privacy",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function PrivacyPage() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <PartnersSectionNavigations1 />
      <>
        <section className="bg-darkPink-900 pt-40 pb-60 relative">
          <img
            className="hidden lg:block absolute left-16 xl:left-36 2xl:left-56 3xl:left-96 top-32"
            src="vista-assets/images/hero/stars-pattern1.svg"
            alt
          />
          <img
            className="hidden lg:block absolute right-4 xl:right-36 2xl:right-56 3xl:right-96 top-32"
            src="vista-assets/images/hero/stars-pattern2.svg"
            alt
          />
          <div className="container mx-auto px-4">
            <h1 className="font-heading text-white text-center text-4xl md:text-5xl font-bold mb-4">
              Privacy Policy
            </h1>
            <p className="text-white text-center text-lg mb-10 max-w-xl xl:max-w-max mx-auto">
              Updated October 2024
            </p>
          </div>
        </section>
        <section className="bg-darkPink-900 pb-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap gap-8 text-white">
              <p>
                Noir eSports and its affiliates (collectively, "we", "us" or
                "our") respect your privacy and commit to protecting it through
                practices described in this Privacy Policy. This Privacy Policy
                Notice explains our collection, use, disclosure and security of
                personal information in connection with the use of our website,
                www.noiresports.com (our "Website"), our applications, or other
                services we provide (collectively, "Services").
              </p>
              <p>You can get hold of us in any of the following ways:</p>
            </div>
            <div className="flex flex-col gap-8 text-white">
              <ul className="list-disc list-inside ml-6">
                <li>
                  By emailing us at{" "}
                  <a
                    href="mailto:team@noiresports.com"
                    className="underline text-white hover:text-opacity-70 transition duration-200"
                  >
                    team@noiresports.com
                  </a>
                </li>
                <li>
                  By writing to us at 128 City Road, London, United Kingdom,
                  EC1V 2NX
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <p>
                We take the privacy, including the security, of personal
                information we hold about you seriously. This privacy notice is
                designed to inform you about how we collect personal information
                about you and how we use that personal information. You should
                read this privacy notice carefully so that you know and can
                understand why and how we use the personal information we
                collect and hold about you.
              </p>
              <p>
                We do not have a Data Protection Officer, but if you have any
                questions about this privacy notice or issues arising from it
                then you should contact our legal team, responsible for matters
                relating to data protection at our organisation, including any
                matters in this privacy notice. You can contact them using the
                details set out above.
              </p>
              <p>
                We may issue you with other privacy notices from time to time,
                including when we collect personal information from you. This
                privacy notice is intended to supplement these and does not
                override them.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Key Definitions
              </h1>
              <p>
                The key terms that we use throughout this privacy notice are
                defined below, for ease:
              </p>
              <p>
                <strong>Data Controller:</strong> under UK data protection law,
                this is the organisation or person responsible for deciding how
                personal information is collected and stored and how it is used.
              </p>
              <p>
                <strong>Data Processor:</strong> a Data Controller may appoint
                another organisation or person to carry out certain tasks in
                relation to the personal information on behalf of, and on the
                written instructions of, the Data Controller. (This might be the
                hosting of a site containing personal data, for example, or
                providing an email marketing service that facilitates mass
                distribution of marketing material to a Data Controller’s
                customer-base.)
              </p>
              <p>
                <strong>Personal Information:</strong> in this privacy notice we
                refer to your personal data as ‘personal information’. ‘Personal
                information’ means any information from which a living
                individual can be identified. It does not apply to information
                which has been anonymised.
              </p>
              <p>
                <strong>Special Information:</strong> certain very sensitive
                personal information requires extra protection under data
                protection law. Sensitive data includes information relating to
                health, racial and ethnic origin, political opinions, religious
                and similar beliefs, trade union membership, sex life and sexual
                orientation and also includes genetic information and biometric
                information.
              </p>
              <h2 className="font-heading text-3xl font-bold">
                Details of personal information which we collect and hold about
                you
              </h2>
              <p>
                Set out below are the general categories and details of
                retention periods in relation to those categories and in each
                case the types of personal information which we collect, use and
                hold about you:
              </p>
              <table className="min-w-full bg-darkPink-800 text-white border border-gray-600">
                <thead>
                  <tr className="bg-darkPink-700">
                    <th className="px-4 py-3 text-left font-semibold">
                      General Category
                    </th>
                    <th className="px-4 py-3 text-left font-semibold">
                      Types of Personal Data in that Category
                    </th>
                    <th className="px-4 py-3 text-left font-semibold">
                      Retention Periods
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Identity Information</td>
                    <td className="px-4 py-3">
                      This is information relating to your identity such as your
                      name (including any previous names and titles), gender,
                      and date of birth.
                    </td>
                    <td className="px-4 py-3">
                      Kept while you are registered; if not, held for 12 months.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Contact Information</td>
                    <td className="px-4 py-3">
                      This includes contact details such as email address,
                      physical addresses, and telephone numbers.
                    </td>
                    <td className="px-4 py-3">
                      Kept while you are registered; if not, held for 12 months.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Account Information</td>
                    <td className="px-4 py-3">
                      This is information related to your client account with
                      us, such as username and password.
                    </td>
                    <td className="px-4 py-3">
                      Kept while you are registered; if not, held for 12 months.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Transaction Information</td>
                    <td className="px-4 py-3">
                      Details of transactions such as goods/services provided,
                      and return details.
                    </td>
                    <td className="px-4 py-3">
                      Kept while you are registered; if not, held for 12 months.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Survey Information</td>
                    <td className="px-4 py-3">
                      Information collected from surveys and feedback.
                    </td>
                    <td className="px-4 py-3">
                      Stored for up to 12 months for internal marketing use.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Marketing Information</td>
                    <td className="px-4 py-3">
                      Information on marketing and communication preferences.
                    </td>
                    <td className="px-4 py-3">
                      Stored for up to 12 months for internal marketing use.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">
                      Website, Device, and Technical Information
                    </td>
                    <td className="px-4 py-3">
                      Information about website usage and technical data (IP
                      address, browser, OS, timezone, location settings, and
                      device information).
                    </td>
                    <td className="px-4 py-3">
                      Stored for up to 12 months for internal marketing use.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                The types of personal data we collect about you may differ from
                person to person, depending on who you are and the relationship
                between us.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Details of special information which we collect and hold about
                you
              </h1>
              <p>
                Special information is explained in section 1 above. We do not
                collect or hold any special information about you.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white">
              <ul className="list-disc list-inside ml-6">
                <li>race;</li>
                <li>ethnic origin;</li>
                <li>politics;</li>
                <li>religion;</li> <li>trade union membership;</li>{" "}
                <li>genetics;</li>
                <li>biometrics;</li> <li>health; </li> <li>sex life;</li>{" "}
                <li>sexual orientation.</li>
              </ul>
              <p>
                We do not collect information from you relating to criminal
                convictions or offences.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Details of how and why we use personal information
              </h1>
              <p>
                We are only able to use your personal information for certain
                legal reasons set out in data protection law. There are legal
                reasons under data protection law other than those listed below,
                but in most cases, we will use your personal information for the
                following legal reasons:
              </p>
              <p>
                <strong>Contract Reason:</strong> this is in order to perform
                our obligations to you under a contract we have entered into
                with you;
              </p>
              <p>
                <strong>Legitimate Interests Reason:</strong> this is where the
                use of your personal information is necessary for our (or a
                third party’s) legitimate interests, so long as that legitimate
                interest does not override your fundamental rights, freedoms or
                interests.
              </p>
              <p>
                <strong>Legal Obligation Reason: </strong> this is where we have
                to use your personal information in order to perform a legal
                obligation by which we are bound; and
              </p>
              <p>
                <strong>Consent Reason:</strong> this is where you have given us
                your consent to use your personal information for a specific
                reason or specific reasons.
              </p>
              <p>
                As explained in section 3 above, there are more sensitive types
                of personal data which require higher levels of protection.
                Where we process such sensitive types of personal data we will
                usually do this in the following circumstances:
                <ul className="list-disc list-inside ml-6">
                  <li>We have your explicit consent; </li>
                  <li>Where you have made the personal data public.</li>
                </ul>
              </p>
              <p>
                So that we are able to provide you with goods and services, we
                will need your personal information. If you do not provide us
                with the required personal information, we may be prevented from
                supplying the goods and services.
              </p>
              <p>
                It is important that you keep your personal information up to
                date. If any of your personal information changes, please
                contact us as soon as possible to let us know. If you do not do
                this then we may be prevented from supplying the goods and
                services to you (for example, if you move address and do not
                tell us, then your goods may be delivered to the wrong address)
              </p>
              <p>
                Where we rely on consent for a specific purpose as the legal
                reason for processing your personal information, you have the
                right under data protection law to withdraw your consent at any
                time. If you do wish to withdraw your consent, please contact us
                using the details set out at the beginning of this notice. If we
                receive a request from you withdrawing your consent to a
                specific purpose, we will stop processing your personal
                information for that purpose, unless we have another legal
                reason for processing your personal information, in which case,
                we will confirm that reason to you.
              </p>
              <p>
                We have explained below the different purposes for which we use
                your personal information and, in each case, the legal reason(s)
                allowing us to use your personal information. Please also note
                the following:
                <ul className="list-disc list-inside ml-6">
                  <li>
                    If we use the Legitimate Interests Reason as the legal
                    reason for which we can use your personal information, we
                    have also explained what that legitimate interest is; and
                  </li>
                  <li>
                    For some of the purposes we may have listed more than one
                    legal reason on which we can use your personal information,
                    because the legal reason may be different in different
                    circumstances. If you need confirmation of the specific
                    legal reason that we are relying on to use your personal
                    data for that purpose, please contact us using the contact
                    details set out at the start of this privacy notice.
                  </li>
                </ul>
              </p>
              <table className="min-w-full bg-darkPink-800 text-white border border-gray-600">
                <thead>
                  <tr className="bg-darkPink-700">
                    <th className="px-4 py-3 text-left font-semibold">
                      Purpose
                    </th>
                    <th className="px-4 py-3 text-left font-semibold">
                      Reason for Using the Personal Information
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Enrollment</td>
                    <td className="px-4 py-3">
                      We collect your personal information to facilitate your
                      enrollment as a member or, player, enabling us to provide
                      goods, services, and digital content while growing our
                      business.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Order Processing</td>
                    <td className="px-4 py-3">
                      Your personal information is essential for processing
                      orders, including payment processing, order updates, and
                      enforcement for outstanding amounts.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">
                      Contract Management and Notifications
                    </td>
                    <td className="px-4 py-3">
                      Personal information helps manage our contractual
                      obligations with you and communicate modifications to our
                      agreement.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Record Keeping</td>
                    <td className="px-4 py-3">
                      We maintain records on guarantees or warranties for goods,
                      services, and digital content, ensuring your entitlement
                      to these benefits.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Enhancement of Offerings</td>
                    <td className="px-4 py-3">
                      Your personal information helps enhance the quality of
                      goods, services, and digital content based on your
                      interactions and preferences.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">
                      Targeted Notifications Regarding Offerings
                    </td>
                    <td className="px-4 py-3">
                      We use personal information to provide tailored
                      recommendations and notifications about goods, services,
                      and digital content relevant to you.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Website Operations</td>
                    <td className="px-4 py-3">
                      Personal information is used to ensure seamless website
                      operation and accurate functionality, safeguarding the
                      optimal user experience.
                    </td>
                  </tr>
                  <tr className="border-t border-gray-600">
                    <td className="px-4 py-3">Website Usage Analysis</td>
                    <td className="px-4 py-3">
                      Analyzing personal information helps us understand
                      customer behaviors, supporting business expansion and
                      aligning with client needs.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                Sometimes we may anonymise personal information so that you can
                no longer be identified from it and use this for our own
                purposes. In addition, sometimes we may use some of your
                personal information together with other people’s personal
                information to give us statistical information for our own
                purposes. Because this is grouped together with other personal
                information and you are not identifiable from that combined data
                we are able to use this.
              </p>
              <p>
                Under data protection laws we can only use your personal
                information for the purposes we have told you about, unless we
                consider that the new purpose is compatible with the purpose(s)
                which we told you about. If we want to use your personal
                information for a different purpose which we do not think is
                compatible with the purpose(s) which we told you about then we
                will contact you to explain this and what legal reason is in
                place to allow us to do this.{" "}
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Details of how we collect personal information and special
                information
              </h1>
              <p>
                We usually collect Identity Information, Contact Information,
                Payment Information, Transaction Information, Survey
                Information, Marketing Information, Special Information;
                directly from you when you fill out a form, survey or
                questionnaire, purchase goods, services and/or digital content
                from us, contact us by email, telephone, in writing or
                otherwise. This includes the personal information which you
                provide to us when you subscribe to our mailing list, enter a
                competition or survey.
              </p>
              <p>
                We may receive some of your personal information from third
                parties or publicly available sources. This includes:
                <ul className="list-disc list-inside ml-6">
                  <li>
                    Contact Information and Payment Information from our
                    selected third-party suppliers, such as Stripe, Odoo,
                    Discord
                  </li>
                  <li>
                    Identity Information and Contact Information from publicly
                    available sources such as Companies House
                  </li>
                  <li>
                    Website, Device and Technical Information from third parties
                    such as analytics providers (like Google, Plausible
                    CloudFlare)
                  </li>
                  <li>religion;</li> <li>trade union membership;</li>{" "}
                  <li>genetics;</li>
                  <li>biometrics;</li> <li>health; </li> <li>sex life;</li>{" "}
                  <li>sexual orientation.</li>
                </ul>
              </p>
              <p>
                We may also receive Website, Device and Technical Information
                automatically from technologies such as cookies which are
                installed on our website. To find out more about these please
                see our cookie policy.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Details about who personal Information may be shared with
              </h1>
              <p>
                We may need to share your personal information with other
                organisations or people. These organisations include:
                <ul className="list-disc list-inside ml-6">
                  <li>
                    Suppliers: such as IT support services, payment providers,
                    administration providers, marketing agencies
                  </li>
                  <li>
                    Government bodies and regulatory bodies: such as HMRC, fraud
                    prevention agencies who are based in the UK & EU
                  </li>
                  <li>
                    Our advisors: such as lawyers, accountants, auditors,
                    insurance companies who are based in the UK & EU.
                  </li>
                  <li>Our bankers;</li>
                  <li>Credit Reference Agencies;</li>
                  <li>Email platforms;</li>
                  <li>
                    any organisations which propose to purchase our business and
                    assets in which case we may disclose your personal
                    information to the potential purchaser.
                  </li>
                </ul>
              </p>
              <p>
                Depending on the circumstances, the organisations or people who
                we share your personal information with will be acting as either
                Data Processors or Data Controllers. When we share your personal
                information with a Data Processor we will ensure that we have in
                place contracts, which set out the responsibilities and
                obligations of us and them, including in respect of security of
                personal information.
              </p>
              <p>
                We do not sell or trade any of the personal information which
                you have provided to us.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Details about transfers to countries outside of the EEA
              </h1>
              <p>
                If any transfer of personal information by us will mean that
                your personal information is transferred outside of the EEA then
                we will ensure that safeguards are in place to ensure that a
                similar degree of protection is given to your personal
                information, as is given to it within the EEA and that the
                transfer is made in compliance with data protection laws
                (including where relevant any exceptions to the general rules on
                transferring personal information outside of the EEA which are
                available to us – these are known as ‘derogations’ under data
                protection laws). We may need to transfer personal information
                outside of the EEA to other organisations within our group.
              </p>
              <p>
                The safeguards set out in data protection laws for transferring
                personal information outside of the EEA include:
                <ul className="list-disc list-inside">
                  <li>
                    where the transfer is to a country or territory which the EU
                    Commission has approved as ensuring an adequate level of
                    protection;
                  </li>
                  <li>
                    where personal information is transferred to another
                    organisation within our group, under an agreement covering
                    this situation which is known as “binding corporate rules”;
                  </li>
                  <li>
                    having in place a standard set of clauses which have been
                    approved by the EU Commission;
                  </li>
                  <li>
                    compliance with an approved code of conduct by a relevant
                    data protection supervisory authority (in the UK, this is
                    the Information Commissioner’s Office (ICO));
                  </li>
                  <li>
                    certification with an approved certification mechanism;
                  </li>
                  <li>
                    where the EU Commission has approved specific arrangements
                    in respect of certain countries, such as the US Privacy
                    Shield, in relation to organisations which have signed up to
                    it in the USA.
                  </li>
                </ul>
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Details about how long we will hold your personal information
              </h1>
              <p>
                We will only hold your personal data for as long as necessary.
                How long is necessary will depend upon the purposes for which we
                collected the personal information and whether we are under any
                legal obligation to keep the personal information (such as in
                relation to accounting or auditing records or for tax reasons).
                We may also need to keep personal information in case of any
                legal claims including in relation to any guarantees or
                warranties which we have provided with the services.
              </p>
              <p>
                We have set out above the details of our retention periods for
                different types of data.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                YOUR RIGHTS UNDER DATA PROTECTION LAW
              </h1>
              <p>
                Under data protection laws you have certain rights in relation
                to your personal information, as follows:
              </p>
              <p>
                <strong>Right to request access:</strong> (this is often called
                ‘subject access’). This is the right to obtain from us a copy of
                the personal information which we hold about you. We must also
                provide you with certain other information in response to these
                requests to help you understand how your personal information is
                being used.
              </p>
              <p>
                <strong>Right to correction:</strong> This is the right to
                request that any incorrect personal data is corrected and that
                any incomplete personal data is completed.
              </p>
              <p>
                <strong>Right to erasure:</strong> (this is often called the
                “right to be forgotten”). This right only applies in certain
                circumstances. Where it does apply, you have the right to
                request us to erase all of your personal information.
              </p>
              <p>
                <strong>Right to restrict processing:</strong> This right only
                applies in certain circumstances. Where it does apply, you have
                the right to request us to restrict the processing of your
                personal information.
              </p>
              <p>
                <strong>Right to data portability:</strong> This right allows
                you to request us to transfer your personal information to
                someone else.
              </p>
              <p>
                <strong>Right to object:</strong> You have the right to object
                to us processing your personal information for direct marketing
                purposes. You also have the right to object to us processing
                personal information where our legal reason for doing so is the
                Legitimate Interests Reason (see section 4 above) and there is
                something about your particular situation which means that you
                want to object to us processing your personal information. In
                certain circumstances, you have the right to object to
                processing where such processing consists of profiling
                (including profiling for direct marketing).
              </p>
              <p>
                In addition to the rights set out in this policy, where we rely
                on consent as the legal reason for using your personal
                information, you have the right to withdraw your consent.
              </p>
              <p>
                If you want to exercise any of the above rights in relation to
                your personal information, please contact us using the details
                set out at the beginning of this notice. If you do make a
                request then please note:
                <ul className="list-disc list-inside">
                  <li>
                    we may need certain information from you so that we can
                    verify your identity;
                  </li>
                  <li>
                    we do not charge a fee for exercising your rights unless
                    your request is unfounded or excessive; and
                  </li>
                  <li>
                    If your request is unfounded or excessive then we may refuse
                    to deal with your request.
                  </li>
                </ul>
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">Marketing</h1>
              <p>
                You may receive marketing from us about similar goods and
                services, where either you have consented to this, or we have
                another legal reason by which we can contact you for marketing
                purposes.
              </p>
              <p>
                However, we will give you the opportunity to manage how or if we
                market to you. In any e-mail which we send to you, we provide a
                link to either unsubscribe or opt-out, or to change your
                marketing preferences. If you have an account with us, you can
                login to your account and manage your preferences there too or
                contact our team directly on team@noiresports.com To change your
                marketing preferences, and/or to request that we stop processing
                your personal information for marketing purposes , you can
                always contact us on the details set out at the beginning of
                this notice.
              </p>
              <p>
                If you do request that we stop marketing to you, this will not
                prevent us from sending communications to you which are not to
                do with marketing (for example in relation to goods, services
                and/or digital content which you have purchased from us).
              </p>
              <p>
                We do not pass your personal information on to any third parties
                for marketing purposes.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Third Party Websites
              </h1>
              <p>
                Our website may contain links to third party websites. If you
                click and follow those links then these will take you to the
                third party website. Those third party websites may collect
                personal information from you and you will need to check their
                privacy notices to understand how your personal information is
                collected and used by them.
              </p>
            </div>
            <div className="flex flex-col gap-8 text-white pt-24">
              <h1 className="font-heading text-3xl font-bold">
                Changes to This Privacy Policy
              </h1>
              <p>
                We reserve the right to change or modify this Privacy Policy or
                any of our tools or services at any time and any changes will be
                effective upon being posted unless we advise otherwise. If we
                make any material changes to this policy we will notify you by
                email (sent to the email address specified when you register)
                and/or by means of a notice on this website prior to the change
                becoming effective. We encourage you to periodically review this
                website for the latest information on our privacy practices. If
                you do not accept the terms of this Privacy Policy, we ask that
                you do not register with us and that you do not use the Noir
                eSports Websites immediately if you do not agree to the terms of
                this Privacy Policy.
              </p>
            </div>
          </div>
        </section>

        <IndexSectionFooter4 />
      </>
    </React.Fragment>
  );
}
