import React from "react";

export default function ContactSectionContact2() {
  return (
    <React.Fragment>
      <>
        <section className="bg-darkPink-900 pt-24 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <p className="mb-6 uppercase text-pink-400 text-xs tracking-widest">
                  CONTACT US
                </p>
                <h1 className="font-heading text-white text-5xl md:text-7xl font-semibold mb-6 max-w-sm md:max-w-md">
                  How can we help you?
                </h1>
                <p className="text-white text-opacity-70 text-lg max-w-md">
                  Our team is here to help you access capital and grow on your
                  terms. Reach out directly if you have any questions.
                </p>
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <div className="rounded-3xl p-3 bg-gradient-to-b from-pink-300 via-pink-700 to-darkPink-900 max-w-xl mx-auto">
                  <div className="rounded-2xl bg-darkPink-900 w-full p-8">
                    <form
                      action="https://api.web3forms.com/submit"
                      method="POST"
                      id="form"
                    >
                      <input
                        type="hidden"
                        name="access_key"
                        value="a5f45c69-76ab-42de-903e-f0afba677f4f"
                      />
                      <div className="flex flex-wrap -mx-4 mb-8">
                        <div className="w-full lg:w-1/2 px-4">
                          <label
                            htmlFor="name"
                            className="block text-sm mb-2 text-white font-medium"
                          >
                            <span>Name</span>
                            <span className="text-pink-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="px-4 py-3 rounded-full mb-8 lg:mb-0 border border-white border-opacity-10 bg-white bg-opacity-5 text-sm placeholder-white placeholder-opacity-50 text-white focus:border-white focus:border focus:border-opacity-50 transition duration-200 outline-none w-full"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                      </div>
                      <label
                        htmlFor="email"
                        className="block text-sm mb-2 text-white font-medium"
                      >
                        <span>Email</span>
                        <span className="text-pink-500">*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="px-4 py-3 mb-8 rounded-full border border-white border-opacity-10 bg-white bg-opacity-5 text-sm placeholder-white placeholder-opacity-50 text-white focus:border-white focus:border focus:border-opacity-50 transition duration-200 outline-none w-full"
                        placeholder="Email address"
                        required
                      />
                      <label
                        htmlFor="subject"
                        className="block text-sm mb-2 text-white font-medium"
                      >
                        <span>Subject</span>
                        <span className="text-pink-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        className="px-4 py-3 mb-8 rounded-full border border-white border-opacity-10 bg-white bg-opacity-5 text-sm placeholder-white placeholder-opacity-50 text-white focus:border-white focus:border focus:border-opacity-50 transition duration-200 outline-none w-full"
                        placeholder="Subject"
                        required
                      />
                      <label
                        htmlFor="message"
                        className="block text-sm mb-2 text-white font-medium"
                      >
                        <span>Message</span>
                        <span className="text-pink-500">*</span>
                      </label>
                      <textarea
                        id="message"
                        rows={5}
                        className="px-4 py-3 mb-8 rounded-2xl resize-none border border-white border-opacity-10 bg-white bg-opacity-5 text-sm text-white focus:border-white focus:border focus:border-opacity-50 transition duration-200 outline-none w-full"
                        placeholder="Enter a message..."
                        defaultValue={""}
                        required
                        name="message"
                      />
                      <button
                        type="submit"
                        className="group relative inline-block p-0.5 w-full font-semibold overflow-hidden rounded-full shadow-pink hover:shadow-none focus:shadow-none"
                      >
                        <div className="absolute inset-0 bg-gradient-to-b from-pink-400 to-pink-500 group-hover:from-white group-focus:from-white group-hover:to-transparent group-focus:to-white group-hover:opacity-40 group-focus:opacity-20 rounded-full" />
                        <div className="relative z-50 py-3 px-4 bg-pink-500 group-hover:bg-pinkSecondary-900 group-focus:bg-pinkSecondary-900 rounded-full transition duration-200">
                          <p className="text-white group-focus:text-opacity-40 transition duration-200">
                            Send Message
                          </p>
                        </div>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
