import React, { useState } from "react";
import images1 from "../../assets/1075657.jpg"; // Make sure this is correctly set

export default function IndexSectionHeaders6() {
  // State to track if the menu is open
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <React.Fragment>
      {/* Section with background image and gradient overlay */}
      <section className="relative overflow-hidden">
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-pink-300 via-pink-700 to-darkPink-900 opacity-90 z-10"></div>

        {/* Main Content */}
        <div className="relative container mx-auto px-4 z-20">
          {/* Navigation */}
          <nav className="py-5 border-b border-white border-opacity-10">
            <div className="container mx-auto px-4">
              <div className="flex items-center justify-between">
                <a href="#" className="text-white font-bold text-2xl">
                  Noir eSports
                </a>
                <div className="hidden lg:flex gap-2 p-1 rounded-full bg-white bg-opacity-10">
                  <a
                    href="/"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Home
                  </a>
                  <a
                    href="/about"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    About
                  </a>
                  <a
                    href="/team"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Team
                  </a>
                  <a
                    href="/partners"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Partners
                  </a>
                  <a
                    href="/contact"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Contact
                  </a>
                </div>
                <a
                  href="/login"
                  className="hidden lg:flex items-center gap-2 text-white hover:text-pink-500 transition duration-200"
                >
                  <span className="text-sm font-medium">Team Login</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.5 3L10.5 8L5.5 13"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <button className="lg:hidden" onClick={toggleMenu}>
                  <svg
                    width={51}
                    height={51}
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={56} height={56} rx={28} fill="white" />
                    <path
                      d="M37 32H19M37 24H19"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </nav>

          <div className="relative pt-24 pb-14">
            <img
              className="hidden lg:block absolute left-16 xl:left-36 2xl:left-56 3xl:left-96 top-56"
              src="vista-assets/images/hero/stars-pattern1.svg"
              alt=""
            />
            <img
              className="hidden lg:block absolute right-4 xl:right-36 2xl:right-56 3xl:right-96 top-56"
              src="vista-assets/images/hero/stars-pattern2.svg"
              alt=""
            />
            <div className="relative z-50">
              <h1 className="font-heading text-white text-center text-4xl sm:text-5xl md:text-7xl font-bold max-w-6xl mx-auto mb-6">
                Unleashing The Darkside Of Gaming
              </h1>
              <div className="mb-40 text-center" />
            </div>
          </div>
        </div>

        {/* Side menu */}
        {isMenuOpen && (
          <div className="fixed top-0 left-0 bottom-0 w-5/6 max-w-xs z-50 block">
            <div
              className="fixed inset-0 bg-black opacity-20"
              onClick={toggleMenu}
            />
            <nav className="relative p-8 w-full h-full bg-white overflow-y-auto">
              <div className="flex items-center justify-between">
                <a href="#" className="inline-block text-2xl">
                  Noir eSports
                </a>
                <button onClick={toggleMenu}>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <ul className="flex flex-col gap-12 py-12 my-12">
                <li>
                  <a href="/" className="text-sm font-medium">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/about" className="text-sm font-medium">
                    About
                  </a>
                </li>
                <li>
                  <a href="/team" className="text-sm font-medium">
                    Team
                  </a>
                </li>
                <li>
                  <a href="/partners" className="text-sm font-medium">
                    Partners
                  </a>
                </li>
                <li>
                  <a href="/contact" className="text-sm font-medium">
                    Contact
                  </a>
                </li>
              </ul>
              <div className="text-center">
                <a
                  href="/login"
                  className="inline-flex items-center gap-2 text-black hover:text-opacity-80 transition duration-200"
                >
                  <span className="text-sm font-medium">Team Login</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.5 3L10.5 8L5.5 13"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </nav>
          </div>
        )}
      </section>
    </React.Fragment>
  );
}
