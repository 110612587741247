import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PartnersSectionNavigations1 from '../components/navigations/PartnersSectionNavigations1';
import PartnersSectionCta2 from '../components/cta/PartnersSectionCta2';
import PartnersSectionLogoClouds4 from '../components/logo-clouds/PartnersSectionLogoClouds4';
import PartnersSectionTestimonials3 from '../components/testimonials/PartnersSectionTestimonials3';
import IndexSectionFooter4 from '../components/footer/IndexSectionFooter4';

const meta = {
  title: 'Noir | Partners',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Partners() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <PartnersSectionNavigations1 />
      <PartnersSectionCta2 />
      <PartnersSectionLogoClouds4 />
      <IndexSectionFooter4 />
    </React.Fragment>
  );
}

