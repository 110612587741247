import React from 'react';

export default function AboutSectionFeatures6() {
    return (
        <React.Fragment>
            <>
                <section className="bg-darkPink-900 py-28">
  <div className="container mx-auto px-4">
    <p className="uppercase text-center text-sweetPink-400 text-xs tracking-widest mb-4">Guided by Integrity, Driven by Passion</p>
    <h1 className="font-heading text-white text-center text-4xl md:text-5xl font-bold mb-4">OUR VALUES</h1>
    <p className="text-white text-opacity-70 text-lg text-center max-w-2xl mx-auto mb-20">At Noir eSports, our values shape every move we make. We believe in the power of teamwork, respect, and relentless pursuit of excellence both on and offline. Together, we rise above challenges and stay true to the principles that define us.</p>
    <div className="flex flex-wrap -m-4">
      <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
        <div className="bg-pinkSecondary-900 rounded-3xl p-6 h-full">
          <div className="rounded-full border border-pink-500 w-10 h-10 flex items-center justify-center mb-4">
            <div className="bg-pinkSecondary-900 w-8 h-8 rounded-full border border-white border-opacity-10 flex items-center justify-center">
              <img className="h-4" src="vista-assets/images/features/money.svg" alt />
            </div>
          </div>
          <p className="text-white text-lg font-bold mb-1">Integrity &amp; Fair Play</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
        <div className="bg-pinkSecondary-900 rounded-3xl p-6 h-full">
          <div className="rounded-full border border-pink-500 w-10 h-10 flex items-center justify-center mb-4">
            <div className="bg-pinkSecondary-900 w-8 h-8 rounded-full border border-white border-opacity-10 flex items-center justify-center">
              <img className="h-4" src="vista-assets/images/features/profile.svg" alt />
            </div>
          </div>
          <p className="text-white text-lg font-bold mb-1">Teamwork</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
        <div className="bg-pinkSecondary-900 rounded-3xl p-6 h-full">
          <div className="rounded-full border border-pink-500 w-10 h-10 flex items-center justify-center mb-4">
            <div className="bg-pinkSecondary-900 w-8 h-8 rounded-full border border-white border-opacity-10 flex items-center justify-center">
              <img className="h-4" src="vista-assets/images/features/chart.svg" alt />
            </div>
          </div>
          <p className="text-white text-lg font-bold mb-1">Communication</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/4 p-4">
        <div className="bg-pinkSecondary-900 rounded-3xl p-6 h-full">
          <div className="rounded-full border border-pink-500 w-10 h-10 flex items-center justify-center mb-4">
            <div className="bg-pinkSecondary-900 w-8 h-8 rounded-full border border-white border-opacity-10 flex items-center justify-center">
              <img className="h-4" src="vista-assets/images/features/cloud.svg" alt />
            </div>
          </div>
          <p className="text-white text-lg font-bold mb-1">Community</p>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

