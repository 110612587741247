import React from "react";

export default function PartnersSectionCta2() {
  return (
    <React.Fragment>
      <>
        <section className="bg-darkPink-900 pt-40 relative">
          <img
            className="hidden lg:block absolute left-16 xl:left-36 2xl:left-56 3xl:left-96 top-32"
            src="vista-assets/images/hero/stars-pattern1.svg"
            alt
          />
          <img
            className="hidden lg:block absolute right-4 xl:right-36 2xl:right-56 3xl:right-96 top-32"
            src="vista-assets/images/hero/stars-pattern2.svg"
            alt
          />
          <div className="container mx-auto px-4">
            <h1 className="font-heading text-white text-center text-4xl md:text-5xl font-bold mb-4">
              We are currently looking for partners!
            </h1>
            <p className="text-white text-center text-lg mb-10 max-w-xl xl:max-w-max mx-auto">
              Get in touch with our team for more information our partnership
              programme.
            </p>
            <div className="relative max-w-sm mx-auto flex justify-center items-center h-64">
              <div className="absolute left-0 top-0 bg-pink-500 blur-xl filter opacity-50" />
              <button
                type="submit"
                className="group relative inline-block p-0.5 font-semibold overflow-hidden rounded-full text-center"
              >
                <div className="relative z-50 flex items-center py-2 px-4 bg-white group-hover:bg-opacity-80 group-focus:bg-opacity-80 rounded-full transition duration-200">
                  <a
                    className="text-pinkSecondary-900"
                    href="mailto:team@noiresports.com"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </div>
              </button>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
