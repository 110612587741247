import React from "react";

export default function AboutSectionAbout7() {
  return (
    <React.Fragment>
      <>
        <section className="bg-darkPink-900 pt-56 pb-14">
          <div className="container mx-auto px-4">
            <h1 className="font-heading text-white text-center mb-28 text-5xl md:text-7xl font-bold max-w-4xl mx-auto">
              A Team Fueled by Passion and Competition
            </h1>
            <div className="relative mx-auto mb-32 max-w-sm">
              <div className="absolute left-0 top-0 bg-pink-500 blur-3xl filter opacity-50 rounded-full h-full w-full" />
              <img
                className="relative z-50"
                style={{ height: 400 }}
                src="https://noiresports.com/a/Noir%20Logos.png"
                alt
              />
            </div>
            <div className="border-b border-pink-500 pb-9 mb-20">
              <div className="flex flex-wrap items-stretch -m-4">
                <div className="w-full md:w-1/3 lg:w-1/2 p-4">
                  <div className="border-r border-transparent md:border-white md:border-opacity-10 flex flex-col justify-center h-full">
                    <p className="text-white text-center md:text-left text-opacity-50 mb-2">
                      ELELE GROUP
                    </p>
                    <p className="text-white text-center md:text-left mx-auto md:mx-0 text-2xl font-semibold max-w-xs">
                      Backed by Our Parent Company
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/3 lg:w-1/4 p-4">
                  <div className="border-r border-transparent md:border-white md:border-opacity-10 flex flex-col justify-center h-full">
                    <p className="text-white text-opacity-50 mb-2 text-center">
                       Followers
                    </p>
                    <p className="text-white text-5xl font-bold text-center">
                      110+
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/3 lg:w-1/4 p-4">
                  <div className="flex flex-col justify-center h-full">
                    <p className="text-white text-opacity-50 mb-2 text-center">
                      Founded in
                    </p>
                    <p className="text-white text-5xl font-bold text-center">
                      2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -m-4 mb-32">
              <div className="w-full lg:w-1/2 p-4">
                <h1 className="text-white text-4xl font-bold mb-6">
                  Our Story
                </h1>
                <p className="text-white text-xl max-w-3xl">
                  Noir Esports is a dynamic online sports organisation
                  established in April 2024. The idea for Noir was sparked after
                  the Copenhagen Major for Counter Strike 2, setting the stage
                  for an ambitious journey in the competitive gaming world.
                  While starting from the grassroots level, we have remained
                  committed to growth and excellence. Today, we continue to
                  expand both our teams, consistently building on our foundation
                  and striving towards becoming a leading force in esports. Each
                  day at Noir is a step forward, fueled by passion, innovation,
                  and a relentless pursuit of success in the ever-evolving realm
                  of electronic sports.
                </p>
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <h1 className="text-white text-4xl font-bold mb-6">
                  Our Mission
                </h1>
                <p className="text-white text-xl max-w-3xl">
                  Noir eSports was founded with a visionary purpose: to advance
                  the professional growth of individuals who are passionate
                  about the burgeoning field of electronic sports. At the heart
                  of our mission is a steadfast commitment to nurturing the
                  careers of gaming athletes by maintaining the highest
                  standards of integrity and reputation. We dedicate ourselves
                  to offering a robust foundation that supports the success of
                  our athletes, ensuring they have the resources and support
                  they need to excel both in and out of the gaming arena.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
