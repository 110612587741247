import React, { useState } from "react";

export default function TeamSectionNavigations3() {
  // State to manage menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <React.Fragment>
      <>
        <section>
          {/* Navigation Bar */}
          <nav className="py-5 bg-darkPink-900">
            <div className="container mx-auto px-4">
              <div className="flex items-center justify-between">
                {/* Logo */}
                <a href="/" className="text-white font-bold text-2xl">
                  Noir eSports
                </a>

                {/* Desktop Menu Links */}
                <div className="hidden lg:flex gap-2 p-1 rounded-full bg-white bg-opacity-10">
                  <a
                    href="/"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Home
                  </a>
                  <a
                    href="/about"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    About
                  </a>
                  <a
                    href="/team"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Team
                  </a>
                  <a
                    href="/partners"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Partners
                  </a>
                  <a
                    href="/contact"
                    className="px-3 py-2 rounded-full text-white text-sm hover:bg-white hover:bg-opacity-20 transition duration-200"
                  >
                    Contact
                  </a>
                </div>

                {/* Desktop Login Link */}
                <a
                  href="/login"
                  className="hidden lg:flex items-center gap-2 text-white hover:text-pink-500 transition duration-200"
                >
                  <span className="text-sm font-medium">Team Login</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.5 3L10.5 8L5.5 13"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>

                {/* Mobile Menu Button */}
                <button className="lg:hidden" onClick={toggleMenu}>
                  <svg
                    width={51}
                    height={51}
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={56} height={56} rx={28} fill="white" />
                    <path
                      d="M37 32H19M37 24H19"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </nav>

          {/* Mobile Menu Overlay */}
          {isMenuOpen && (
            <div className="fixed top-0 left-0 bottom-0 w-5/6 max-w-xs z-50 bg-white">
              {/* Background Overlay */}
              <div
                className="fixed inset-0 bg-black opacity-20"
                onClick={toggleMenu}
              />

              {/* Side Menu */}
              <nav className="relative p-8 w-full h-full overflow-y-auto z-50">
                <div className="flex items-center justify-between">
                  <a href="/" className="text-2xl font-bold">
                    Noir eSports
                  </a>
                  <button onClick={toggleMenu}>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 18L18 6M6 6L18 18"
                        stroke="#111827"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>

                {/* Mobile Menu Links */}
                <ul className="flex flex-col gap-8 py-12 my-12">
                  <li>
                    <a
                      href="/"
                      className="text-lg font-medium text-darkPink-900 hover:text-pink-600 transition duration-200"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about"
                      className="text-lg font-medium text-darkPink-900 hover:text-pink-600 transition duration-200"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/team"
                      className="text-lg font-medium text-darkPink-900 hover:text-pink-600 transition duration-200"
                    >
                      Team
                    </a>
                  </li>
                  <li>
                    <a
                      href="/partners"
                      className="text-lg font-medium text-darkPink-900 hover:text-pink-600 transition duration-200"
                    >
                      Partners
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className="text-lg font-medium text-darkPink-900 hover:text-pink-600 transition duration-200"
                    >
                      Contact
                    </a>
                  </li>
                </ul>

                {/* Login Link */}
                <div className="text-center">
                  <a
                    href="/login"
                    className="inline-flex items-center gap-2 text-darkPink-900 hover:text-opacity-80 transition duration-200"
                  >
                    <span className="text-lg font-medium">Team Login</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.5 3L10.5 8L5.5 13"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </nav>
            </div>
          )}
        </section>
      </>
    </React.Fragment>
  );
}
